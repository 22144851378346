import { transformObjectToLocaleMap } from '@/utils/intl';

export default {
  // region Login Page
  'pages.login.welcome': 'Welcome! Sign In',
  'pages.layouts.userLayout.title': 'Start your kitchen sales talk online',
  'pages.login.username.placeholder': 'Email Address',
  'pages.login.password.placeholder': 'Password',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'Forgot Password ?',
  'pages.login.dontHaveAnAccount': "Don't have an account?",
  'pages.login.requestDemo': 'Request a Demo',
  'pages.login.submit': 'Login',
  'pages.login.password.required': 'Please input your password!',
  'pages.login.failure': 'Login failed, please try again!',
  'pages.login.accountLogin.errorMessage': 'Incorrect username/password',
  'pages.login.username.required': 'Please input your username!',
  'pages.login.success': 'Login successful!',
  'pages.login.loginTitle': 'Welcome! Sign In',
  'pages.login.dontHaveAccount': "Don't have an Account?",
  'app.footer.imprint': 'Imprint',
  'app.footer.policy': 'Policy',
  'pages.login.email.required': 'Please Enter Email',
  // endregion

  // region Reset Password Page
  'pages.resetPassword.setNewPassword': 'Set new password',
  'pages.resetPassword.placeholder': 'Password',
  'pages.resetPassword.placeholderConfirm': 'Confirm Password',
  'pages.resetPassword.passwordRequired': 'Please enter password',
  'pages.resetPassword.backToLogin': 'Back to Login',
  'pages.resetPassword.title': 'Assign A New Password',
  'pages.resetPassword.subTitle': 'Enter A New Password',
  'pages.resetPassword.errorMessage':
    'Your password must have at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character.',
  'pages.resetPassword.wrongConfirmation': 'The two passwords that you entered do not match!',
  'pages.forgotPassword.hint': 'To which Email should the password reset information be sent to?',
  // endregion

  // region Forgot Password Page
  'pages.forgotPassword.label': 'Email Address',
  'pages.forgotPassword.required': 'This field is required! please type your email address',
  'pages.forgotPassword.placeholder': 'Email Address',
  'pages.forgotPassword.tooltip':
    'If you do not remember your email address please contact us through support@conversional.de',
  'pages.forgotPassword.errorMessage':
    'There is an error with your email address, please contact support through the contact us page.',
  'pages.forgotPassword.invalid':
    'The email your entered is not registered. Please type the correct email.',
  'pages.forgotPassword.submit': 'Send Request',
  'pages.forgotPassword.login': 'Back to login',
  'pages.forgotPassword.guidText': 'To which email should the password reset information be sent?',
  'pages.forgotPassword.sentMail': 'Reset Password Email Sent Successfully.',
  'pages.forgotPassword.sentMailSubtitle': 'Please Check your Spam too.',
  'pages.forgotPassword.forgotPasswordTitle': 'Reset Password',
  // endregion

  // region Dashboard Page
  'pages.dashboard.header': 'Dashboard',
  // endregion

  // region Marketing Page
  'pages.marketing.header': 'Marketing',
  'pages.marketing.noChart': 'No Chart Available. Try Changing Date Filter',
  // endregion

  // region Performance Page
  'pages.performance.header': 'Performance',
  'pages.performance.alertMessage': 'This is the performance Page',
  'pages.performance.cardTitle': 'Performance Funnel',
  'pages.performance.instantAppointmentPath': 'Appointment path',
  'pages.performance.inspirationPath': 'Inspiration path',
  'pages.performance.infoText':
    'This data is based on tracking events. Due to tracking blockers and sampling this data will not represent all users. You may see different values compared to the dashboard.',
  'pages.performance.paths': 'Paths',
  'pages.performance.explanation':
    "Paths can be initiated from the main questionnaire. Their 'started' value refers to the path's start, not the tool's start. Therefore, the starts of all paths are lower than the tool starts if users cancel in the first step.",
  // endregion

  // region Customers Page
  'pages.customers.header': 'Customers',
  'pages.customers.tableTitle': 'Customers',
  'pages.customers.engagementScore': 'Engagement Score',
  'pages.customers.scoreExplanation':
    'Estimated probability this customer will buy in your store based on all previous interactions.',
  'pages.customers.name': 'Name',
  'pages.customers.gender': 'Gender',
  'pages.customers.email': 'Email',
  'pages.customers.utmMedium': 'UTM Medium',
  'pages.customers.status': 'Status',
  'pages.customers.avtiveRecipient': 'Active Recipient',
  'pages.customers.doubleOptin': 'Double opt-in',
  'pages.customers.createDate': 'Create Date',
  'pages.customers.actions': 'Actions',
  'pages.customers.details': 'Details',
  'pages.customers.actions.download': 'Download as CSV',
  'pages.customers.profile': 'Profile',
  'pages.customers.firstName': 'First Name',
  'pages.customers.lastName': 'Last Name',
  'pages.customers.styles': 'Styles',
  'pages.customers.qualification': 'Qualification',
  'pages.customers.phone': 'Phone',
  'pages.customers.activeRecipient': 'Active Recipient',
  'pages.customer.unsubscribe': 'Unsubscribe',
  'pages.customer.qualification': 'Qualification',
  'pages.customer.state': 'State',
  'pages.customer.orderVolume': 'Order Volume',
  'pages.customer.notes': 'Notes',
  'pages.customer.results': 'Results',
  'pages.customer.uploadedFiles': 'Uploaded Files',
  'pages.customer.noUploadedFiles': 'No Uploaded Files',

  // endregion

  // region Requests Page
  'pages.requests.header': 'Customers',
  'pages.requests.tableTitle': 'Requests',
  'pages.requests.customer': 'Customer',
  'pages.requests.appointmentRequest': 'Appointment Request',
  'pages.requests.what': 'What',
  'pages.requests.dateOfRequest': 'Date of Request',
  'pages.requests.where': 'Where',
  'pages.requests.actions': 'Actions',
  'pages.requests.details': 'Details',
  // endregion

  // region Locations page
  'pages.locations.header': 'Locations',
  'pages.locations.alertMessage': 'This is the locations page',
  'pages.locations.location': 'Location',
  'pages.locations.newLocation': 'New Location',
  'pages.locations.addLocation': 'New Location',
  'pages.locations.address': 'Address',
  'pages.locations.videoCall': 'Video Call',
  'pages.locations.created': 'Create Date',
  'pages.locations.actions': 'Actions',
  'pages.locations.details': 'Details',
  'pages.location.edit': 'Edit',
  'pages.locations.availability': 'Open Hours',
  'pages.locations.blockHours': 'Block Hours',
  'pages.locations.additionalOpenHours': 'Additional Open Hours',
  'pages.locations.from': 'From',
  'pages.locations.fromHour': 'From',
  'pages.locations.to': 'To',
  'pages.locations.toHour': 'To',
  'pages.location.removeRecurringTime': 'Are you sure to remove this recurring time?',
  'pages.locations.download': 'Download as CSV',
  'pages.common.add': 'Add',
  'pages.common.remove': 'Delete',
  'pages.common.details': 'Details',
  'pages.common.edit': 'Edit',
  'pages.common.actions': 'Actions',
  'pages.common.clear': 'Clear',
  'pages.common.clearAll': 'Clear all',
  'pages.common.selected': 'Selected',
  'pages.common.save': 'Save',
  'pages.common.discard': 'Discard',
  'pages.common.form.required': 'This Field is Required.',
  'pages.common.form.validationError': 'Please fill out all the required fields.',
  'pages.common.yes': 'yes',
  'pages.common.no': 'no',
  'pages.common.ok': 'Ok',
  'pages.common.cancel': 'Cancel',
  'pages.common.tutorial': 'Tutorial',
  'pages.common.confirm': 'Confirm',
  'pages.images.deleteSelectedImagesSuccess': 'Images deleted successfully',
  'pages.location.removeBlockPeriod': 'Are you sure to remove this block period?',
  'pages.location.removeOpenPeriod': 'Are you sure to remove this open period?',
  'pages.location.name': 'Name',
  'pages.location.home': 'At Home',
  'pages.location.store': 'Store',
  'pages.location.online': 'Online',
  'pages.location.type': 'Type',
  'pages.location.type_tooltip':
    'The type of a location defines what kind of appointment is booked.',
  'pages.location.address': 'Address',
  'pages.location.createDate': 'Create Date',
  'pages.location.videoCallEnabled': 'Active',
  'pages.location.videoCallDisabled': 'Inactive',
  'pages.location.videoCall': 'Video Call',
  'pages.location.details': 'Details',
  'pages.locations.currentRecurring': 'Current Recurring time',
  'pages.locations.resources': 'Resources',
  'pages.locations.meta': 'Custom Fields',
  'pages.locations.resourceCountLimitation':
    'You cannot add more than 5 resources to a location. If you need more resources please contact support.',
  'pages.locations.atLeastOneResource': 'Each location requires at least one resource.',
  'pages.location.removeResource': 'Are you sure to remove this resource?',
  'pages.locations.addNewResource': 'Add New Resources',
  'pages.locations.removeResourceSuccess': 'Resource removed.',
  'pages.locations.removeResourceFailed': 'Failed to remove resource.',
  'pages.locations.addNewResourceSuccess': 'New resource added.',
  'pages.locations.addNewResourceFailed': 'Failed to add new resource.',
  'pages.location.resource.name': 'Name',
  'pages.location.resource.email': 'Email',
  // Location Removal
  'pages.locations.removeLocationConfirm': 'Are you sure to remove this location?',
  'pages.locations.removeLocationSuccess': 'Location removed successfully.',
  'pages.locations.removeLocationFailed': 'Failed to remove location',
  //
  'pages.resource.name': 'Name',
  'pages.resource.surname': 'Surname',
  'pages.resource.email': 'Email',

  // endregion

  // region Account Settings
  'pages.settings.header': 'Account Settings',
  'pages.settings.profileTab': 'Profile',
  'pages.settings.billingTab': 'Billing',
  'pages.settings.languageTab': 'Language',
  'pages.settings.firstName.label': 'First Name',
  'pages.settings.firstName.required': 'This field is required! please type your first name',
  'pages.settings.firstName.placeholder': 'First Name',
  'pages.settings.firstName.errorMessage':
    'Your first name can not contain numbers and symbols. Please correct your first name.',
  'pages.settings.lastName.label': 'Last Name',
  'pages.settings.lastName.required': 'This field is required! please type your last name',
  'pages.settings.lastName.placeholder': 'Last Name',
  'pages.settings.lastName.errorMessage':
    'Your last name can not contais numbers and symbols. Please correct your last name.',
  'pages.settings.email.label': 'Email Address',
  'pages.settings.email.required': 'This field is required! please type your email address',
  'pages.settings.email.placeholder': 'Email Address',
  'pages.settings.email.errorMessage':
    'There is an error with your Email address, please write your email in a valid format',
  'pages.settings.phone.label': 'Phone',
  'pages.settings.phone.required': 'This field is required! please type your phone',
  'pages.settings.phone.placeholder': 'Phone',
  'pages.settings.phone.errorMessage':
    'Your phone can not contains characters. Please correct your phone number.',
  'pages.settings.address.label': 'Address',
  'pages.settings.address.required': 'This field is required! please type your address',
  'pages.settings.address.placeholder': 'Address',
  'pages.settings.saveSettings': 'Save Changes',

  // region Account Settings page - Billings tab
  'pages.billings.header': 'Billings',
  'pages.billings.alertMessage': 'This is the Billings page',
  'pages.billings.questionnaire': 'Questionnaire',
  'pages.billings.customer': 'Customer',
  'pages.billings.journey': 'Journey',
  'pages.billings.event': 'Event',
  'pages.billings.period': 'Period',
  'pages.billings.created': 'Created',
  'pages.billings.mode': 'Mode',
  'pages.billings.subtotal': 'Subtotal',
  'pages.billings.discount': 'Discount',
  'pages.billings.total': 'Total',
  'pages.BillingDetails': 'Billing Details',
  // endregion

  // region Account Settings page - Language tab
  'pages.language.title': 'Choose your language',
  'pages.language.english': 'English',
  'pages.language.german': 'Deutsch',
  'pages.language.french': 'Francais',
  // endregion
  // endregion

  // region Profile Page
  'pages.profile.firstName': 'First Name',
  'pages.profile.lastName': 'Last Name',
  'pages.profile.email': 'Email',
  'pages.profile.phone': 'Phone',
  'pages.profile.address': 'Address',
  'pages.profile.saveChanges': 'Save Changes',
  // endregion

  // region Support Page
  'pages.support.header': 'Support',
  'pages.settings.telephone': 'Telephone',
  'pages.settings.email': 'Email',
  'pages.support.telephone': 'Telephone',
  'pages.support.email': 'Email',
  'pages.support.title': 'Contact our technical support',
  // endregion

  // region Service Request
  'pages.serviceRequest.customer': 'Customer',
  'pages.customers.created': 'Created At',
  'pages.serviceRequest.inquiry': 'Inquiry',
  'pages.serviceRequest.what': 'What',
  'pages.serviceRequest.when': 'When',
  'pages.serviceRequest.where': 'Where',
  'pages.serviceRequest.qualification': 'Qualification',

  // endregion

  // region Date and Weekdays
  'utils.date.7Days': 'Last 7 Days',
  'utils.date.30Days': 'Last 30 Days',
  'utils.date.90Days': 'Last 90 Days',
  'utils.date.180Days': 'Last 180 Days',
  // endregion

  // region Images Page

  'pages.images.tags': 'Tags',
  'pages.images.tagGroups': 'Tag groups',
  'pages.images.removeImageConfirmation':
    'Are you sure to remove this image? The action cannot be undone.',
  'pages.images.chooseGroup': 'Choose group for tag: ',
  'pages.images.newTag': 'New tag',
  'pages.images.createTag': 'Create tag',
  'pages.images.editTag': 'Edit tag',
  'pages.images.createTagGroup': 'Create tag group',
  'pages.images.remove': 'Remove',
  'pages.images.removeTagGloballyTitle': 'Are you sure to remove this tag?',
  'pages.images.removeTagGloballySubTitle':
    'Removing the tag globally means that it will be removed from all the images as well',
  'pages.images.upload': 'Upload',
  'pages.images.uploadDrawerTitle': 'Upload images',
  'pages.images.uploadDraggerText': 'Click or drag files or folder to this area to upload',
  'pages.images.uploadDraggerHint': 'Upload images with highest resolution.',
  'pages.images.file': 'File',
  'pages.images.directory': 'Directory',
  'pages.images.selectToBulkEdit': 'Select images to tag in bulk',
  'pages.images.selectTagStatistics': 'Statistics',
  'pages.images.deleteSelectedImagesConfirm': 'Do you want to delete (x) images?',
  'pages.images.contactSupportToSetUpImageGallery':
    'You are currently not using a custom image gallery. If you want to have more control over your own images, please get in touch.',
  'pages.images.noCustomImageGalleryAvailable': 'You do not have a custom image gallery yet.',
  'pages.images.requestAccess': 'Request Custom Gallery',

  // endregion

  // region CTA Page
  'pages.cta.performance': 'Click / Conversion',
  'pages.cta.page': 'Page',
  'pages.cta.conversions.help': 'Users who finished the questionnaire with and without appointment',
  // endregion

  // region Promotions Page

  'pages.promotions.help_1': 'What are promotions?',
  'pages.promotions.help_2':
    'You can add promotional events to your e-mails. Just create and activate a promotion and it will be automatically shown to your customers during the given promotional period.',
  'pages.promotions.tableTitle': 'Promotions',
  'pages.promotions.showVideoTutorial': 'Show video tutorial',
  'pages.promotions.newPromotion': 'New Promotion',
  'pages.promotions.editPromotion': 'Edit Promotion',
  'pages.promotions.addPromotion': 'Add Promotion',
  'pages.promotions.removePromotionConfirm': 'Are you sure to remove this promotion?',
  'pages.promotions.type': 'Promotion type',
  'pages.promotions.title': 'Title',
  'pages.promotions.start': 'Start',
  'pages.promotions.end': 'End',
  'pages.promotions.dates': 'Dates',
  'pages.promotions.modifiedDate': 'Last Modified',
  'pages.promotions.removePromotionsSuccess': 'Promotion removed successfully',
  'pages.promotions.removePromotionsFailed': 'Cannot remove the promotion',
  'pages.promotions.form': 'Form',
  'pages.promotions.preview': 'Preview',
  'pages.promotions.promotionCondition': 'Promotion terms and conditions',
  'pages.promotions.addPromotionCondition': 'Add promotion terms and conditions',
  'pages.promotion.selectPromotionConditionPlaceholder': 'Please select a condition',
  'pages.promotions.invalid_error':'Please enter a valid URL',

  // endregion

  // region statistics
  'pages.stats.selectTagGroups': 'Select up to 3 tag groups that you would like to compare',
  'pages.stats.tagCombination': 'Tag Combination',
  'pages.stats.frequency': 'Frequency',
  // endregion

  // region Settings - visibility
  'pages.settings.visibility.active': 'Active',
  'pages.settings.visibility.active.tooltip': 'You can deactivate your Journey on all pages',
  ...transformObjectToLocaleMap('pages.settings.visibility.example', {
    hint: 'You can enter a URL or a regular expression, for filters that cover sites based on rules.',
    exampleTitle: 'Examples',
    urls: [
      {
        value: '/kitchen/i',
        description: 'All page URLs that contain "kitchen"',
      },
      {
        value: '/kitchen/(.*)/i',
        description: 'All kitchen sub-sites that contain "kitchen/"',
      },
      {
        value: '/https://domain.com/kitchen/i',
        description: 'All pages that start with "https://domain.com/kitchen"',
      },
    ],
  }),
  'pages.settings.visibility.blackList': 'Exclude pages',
  'pages.settings.visibility.blackList.tooltip':
    'The journey will only be shown on the here listed pages',
  'pages.settings.visibility.whiteList': 'Show only on',
  'pages.settings.visibility.whiteList.tooltip':
    'The Journey will be shown on all pages except the listed ones',
  'pages.settings.configuration.editSuccessMessage': 'Settings updated successfully!',
  'pages.settings.visibility.clearRules': 'Please remove all rules to change the mode.',
  // endregion

    // region ForbiddenPage Page
    'pages.ForbiddenPage.message': 'You do not have permission to access this page.',
    'pages.users.newUser': 'New user',
   'pages.users.removeUserConfirmation':'Are you sure you want to remove the user',
   'pages.users.tableTitle':'Users',
   'pages.users.user':'User',
    'pages.users.email':'Email',
    "pages.users.role":"Role",
  'pages.users.selectPlaceholder': 'Please select role',
  'pages.users.addUser': 'Add User',
  'pages.users.close': 'Close',
    // endregion
};
