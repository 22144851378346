import { transformObjectToLocaleMap } from '@/utils/intl';

export default {
  // region Login Page
  'pages.login.welcome': '¡Bienvenido! Regístrese',
  'pages.layouts.userLayout.title': 'Inicie en línea su charla sobre la compra de cocinas',
  'pages.login.username.placeholder': 'Dirección de correo electrónico',
  'pages.login.password.placeholder': 'Contraseña',
  'pages.login.rememberMe': 'Recuérdeme',
  'pages.login.forgotPassword': '¿Ha olvidado su contraseña?',
  'pages.login.dontHaveAnAccount': '¿Aún no tiene una cuenta?',
  'pages.login.requestDemo': 'Solicite una demo',
  'pages.login.submit': 'Inicio de sesión',
  'pages.login.password.required': '¡Introduzca su contraseña!',
  'pages.login.failure': '¡Inicio de sesión fallido. Por favor intente nuevamente!',
  'pages.login.accountLogin.errorMessage': 'Nombre de usuario/contraseña incorrectos',
  'pages.login.username.required': '¡Introduzca su nombre de usuario!',
  'pages.login.success': '¡Inicio de sesión exitoso!',
  'pages.login.loginTitle': '¡Bienvenido! Regístrese',
  'pages.login.dontHaveAccount': '¿Aún no tiene una cuenta?',
  'app.footer.imprint': 'Aviso legal',
  'app.footer.policy': 'Política empresarial',
  'pages.login.email.required': 'Introduzca su correo electrónico',
  // endregion

  // region Reset Password Page
  'pages.resetPassword.setNewPassword': 'Establecer una nueva contraseña',
  'pages.resetPassword.placeholder': 'Contraseña',
  'pages.resetPassword.placeholderConfirm': 'Confirmar contraseña',
  'pages.resetPassword.passwordRequired': '¡Introduzca su contraseña!',
  'pages.resetPassword.backToLogin': 'Volver a inicio de sesión',
  'pages.resetPassword.title': 'Crear una contraseña nueva',
  'pages.resetPassword.subTitle': 'Introduzca una contraseña nueva',
  'pages.resetPassword.errorMessage':
    'Su contraseña debe contener al menos 8 caracteres: una letra mayúscula, una letra minúscula, un número y un carácter especial.',
  'pages.resetPassword.wrongConfirmation': 'Las dos contraseñas que ha introducido no coinciden.',
  'pages.forgotPassword.hint':
    '¿A qué dirección de correo electrónico debe enviarse la información para restablecer la contraseña?',
  // endregion

  // region Forgot Password Page
  'pages.forgotPassword.label': 'Dirección de correo electrónico',
  'pages.forgotPassword.required':
    '¡Este campo es mandatario! Introduzca su dirección de correo electrónico',
  'pages.forgotPassword.placeholder': 'Dirección de correo electrónico',
  'pages.forgotPassword.tooltip':
    'En caso de que no recuerde su dirección de correo electrónico, póngase en contacto con nosotros a través de support@conversional.de',
  'pages.forgotPassword.errorMessage':
    'Se ha producido un error con su dirección de correo electrónico, póngase en contacto con el servicio de asistencia a través de la página de contacto.',
  'pages.forgotPassword.invalid':
    'La dirección de correo electrónico que ha introducido no está inscrita. Introduzca el correo electrónico correcto.',
  'pages.forgotPassword.submit': 'Enviar solicitud',
  'pages.forgotPassword.login': 'Volver a inicio de sesión',
  'pages.forgotPassword.guidText':
    '¿A qué dirección de correo electrónico debe enviarse la información para restablecer la contraseña?',
  'pages.forgotPassword.sentMail':
    'El correo electrónico para restablecer la contraseña se ha enviado con éxito.',
  'pages.forgotPassword.sentMailSubtitle': 'Revise también su correo no deseado.',
  'pages.forgotPassword.forgotPasswordTitle': 'Restablecer contraseña',
  // endregion

  // region Dashboard Page
  'pages.dashboard.header': 'Dashboard',
  // endregion

  // region Marketing Page
  'pages.marketing.header': 'Marketing',
  'pages.marketing.noChart': 'No hay gráfico disponible. Intente cambiar el filtro de fecha',
  // endregion

  // region Performance Page
  'pages.performance.header': 'Rendimiento',
  'pages.performance.alertMessage': 'Esta es la página de rendimiento',
  'pages.performance.cardTitle': 'Funnel de ventas',
  'pages.performance.infoText':
    'Estos datos se basan en eventos de rastreo. Debido a los bloqueadores de rastreo y al muestreo, no todos los usuarios quedan registrados. Puede que haya diferencias con los datos del cuadro de mandos.',
  'pages.performance.paths': 'Rutas',
  'pages.performance.explanation':
    'Las rutas se inician desde el cuestionario. Sus valores "iniciados" se refieren a la ruta, no a los inicios de la herramienta. Por lo tanto, los inicios de todas las rutas son inferiores a los inicios de la herramienta en caso de que los usuarios cancelen en el primer paso.',
  // endregion

  // region Customers Page
  'pages.customers.header': 'Clientes',
  'pages.customers.tableTitle': 'Clientes',
  'pages.customers.scoreExplanation':
    'Probabilidad estimada de que este cliente compre en su tienda basada en todas las interacciones previas.',
  'pages.customers.engagementScore': 'Score de Compromiso',
  'pages.customers.name': 'Nombre',
  'pages.customers.gender': 'Género',
  'pages.customers.email': 'Correo electrónico',
  'pages.customers.utmMedium': 'Medio de contacto',
  'pages.customers.status': 'Estatus',
  'pages.customers.avtiveRecipient': 'Destinatario activo',
  'pages.customers.doubleOptin': 'Double opt-in',
  'pages.customers.createDate': 'Crear fecha',
  'pages.customers.actions': 'Medidas',
  'pages.customers.details': 'Detalles',
  'pages.customers.actions.download': 'Descargar como CSV',
  'pages.customers.profile': 'Perfil',
  'pages.customers.firstName': 'Nombre',
  'pages.customers.lastName': 'Apellidos',
  'pages.customers.styles': 'Estilos',
  'pages.customers.qualification': 'Titulación',
  'pages.customers.phone': 'Teléfono',
  'pages.customers.activeRecipient': 'Destinatario activo',
  'pages.customer.unsubscribe': 'Cancelar la suscripción',
  'pages.customer.qualification': 'Titulación',
  'pages.customer.state': 'Estado',
  'pages.customer.orderVolume': 'Volumen de pedido',
  'pages.customer.notes': 'Comentarios',
  'pages.customer.results': 'Resultados',
  'pages.customer.uploadedFiles': 'Archivos Subidos',
  'pages.customer.noUploadedFiles': 'No Hay Archivos Subidos',

  // endregion

  // region Requests Page
  'pages.requests.header': 'Clientes',
  'pages.requests.tableTitle': 'Solicitudes',
  'pages.requests.customer': 'Cliente',
  'pages.requests.appointmentRequest': 'Solicitud de cita previa',
  'pages.requests.what': 'Que:',
  'pages.requests.dateOfRequest': 'Fecha de solicitud',
  'pages.requests.where': 'Donde:',
  'pages.requests.actions': 'Medidas',
  'pages.requests.details': 'Detalles',
  // endregion

  // region Locations page
  'pages.locations.header': 'Ubicaciones',
  'pages.locations.alertMessage': 'Esta es la página de ubicaciones',
  'pages.locations.location': 'Ubicación',
  'pages.locations.address': 'Dirección',
  'pages.locations.videoCall': 'Videollamada',
  'pages.locations.created': 'Crear fecha',
  'pages.locations.actions': 'Medidas',
  'pages.locations.details': 'Detalles',
  'pages.location.edit': 'Editar',
  'pages.locations.availability': 'Horarios de apertura',
  'pages.locations.blockHours': 'Bloque de horas',
  'pages.locations.additionalOpenHours': 'Horarios de apertura complementarios',
  'pages.locations.from': 'De',
  'pages.locations.fromHour': 'De',
  'pages.locations.to': 'a',
  'pages.locations.toHour': 'a',
  'pages.location.removeRecurringTime': '¿Está seguro de eliminar este horario habitual?',
  'pages.locations.download': 'Descargar como CSV',
  'pages.common.add': 'Agregar',
  'pages.common.remove': 'Eliminar',
  'pages.common.actions': 'Acciones',
  'pages.common.clear': 'Borrar',
  'pages.common.clearAll': 'Borrar todo',
  'pages.common.selected': 'Seleccionado',
  'pages.common.save': 'Ahorrar',
  'pages.common.discard': 'Desechar',
  'pages.common.edit': 'Editar',
  'pages.common.form.required': 'Este campo es mandatario.',
  'pages.common.yes': 'sí',
  'pages.common.no': 'no',
  'pages.common.ok': 'OK',
  'pages.common.cancel': 'Cancelar',
  'pages.common.tutorial': 'Tutorial',
  'pages.common.confirm': 'Confirmar',
  'pages.images.deleteSelectedImagesSuccess': 'Imágenes eliminadas con éxito.',
  'pages.location.removeBlockPeriod': '¿Está seguro de eliminar este período en bloque?',
  'pages.location.removeOpenPeriod': '¿Está seguro de eliminar este periodo de apertura?',
  'pages.location.name': 'Nombre',
  'pages.location.home': 'En casa',
  'pages.location.store': 'Tienda',
  'pages.location.online': 'En línea',
  'pages.location.type': 'Tipo',
  'pages.location.type_tooltip': 'El tipo de sede define qué tipo de cita se reserva.',
  'pages.location.address': 'Dirección',
  'pages.location.createDate': 'Crear fecha',
  'pages.location.videoCallEnabled': 'Activo',
  'pages.location.videoCallDisabled': 'Inactivo',
  'pages.location.videoCall': 'Videollamada',
  'pages.location.details': 'Detalles',
  'pages.locations.currentRecurring': 'Horario habitual actual',
  'pages.locations.resources': 'Recursos',
  'pages.locations.meta': 'Meta',
  'pages.locations.resourceCountLimitation':
    'No puede añadir más de 5 representantes a una ubicación. Si necesita más recursos, póngase en contacto con el servicio de asistencia.',
  'pages.locations.atLeastOneResource': 'Cada sede requiere al menos un representante.',
  'pages.location.removeResource': '¿Está seguro de eliminar este recurso?',
  'pages.locations.addNewResource': 'Añadir recursos nuevos',
  'pages.locations.removeResourceSuccess': 'Recurso eliminado.',
  'pages.locations.removeResourceFailed': 'No se ha podido eliminar el recurso.',
  'pages.locations.addNewResourceSuccess': 'Nuevo recurso añadido.',
  'pages.locations.addNewResourceFailed': 'No se ha podido añadir un nuevo recurso.',
  'pages.location.resource.name': 'Nombre',
  'pages.location.resource.email': 'Correo electrónico',
  // Location Removal
  'pages.locations.removeLocationConfirm': 'Esta seguro de que quiere eliminar este local?',
  'pages.locations.removeLocationSuccess': 'Local eliminado exitosamente',
  'pages.locations.removeLocationFailed': 'No fue posible eliminar el local',
  //
  'pages.resource.name': 'Nombre',
  'pages.resource.surname': 'Apellidos',
  'pages.resource.email': 'Correo electrónico',

  // endregion

  // region Account Settings
  'pages.settings.header': 'Configuración de cuenta',
  'pages.settings.profileTab': 'Perfil',
  'pages.settings.billingTab': 'Facturación',
  'pages.settings.languageTab': 'Idioma',
  'pages.settings.firstName.label': 'Nombre',
  'pages.settings.firstName.required': '¡Este campo es mandatario! Introduzca su nombre',
  'pages.settings.firstName.placeholder': 'Nombre',
  'pages.settings.firstName.errorMessage':
    'Su nombre no puede contener cifras ni símbolos. Corrija su nombre.',
  'pages.settings.lastName.label': 'Apellido',
  'pages.settings.lastName.required': '¡Este campo es mandatario! Introduzca su apellido',
  'pages.settings.lastName.placeholder': 'Apellido',
  'pages.settings.lastName.errorMessage':
    'Su apellido no puede contener cifras ni símbolos. Corrija su apellido.',
  'pages.settings.email.label': 'Dirección de correo electrónico',
  'pages.settings.email.required':
    '¡Este campo es mandatario! Introduzca su dirección de correo electrónico',
  'pages.settings.email.placeholder': 'Dirección de correo electrónico',
  'pages.settings.email.errorMessage':
    'Existe un error con su dirección de correo electrónico, por favor introduzca su dirección de correo electrónico en un formato válido',
  'pages.settings.phone.label': 'Teléfono',
  'pages.settings.phone.required': '¡Este campo es mandatario! Introduzca su número de teléfono',
  'pages.settings.phone.placeholder': 'Teléfono',
  'pages.settings.phone.errorMessage':
    'Su número de teléfono no puede contener caracteres. Corrija su número de teléfono.',
  'pages.settings.address.label': 'Dirección',
  'pages.settings.address.required': '¡Este campo es mandatario! Introduzca su dirección',
  'pages.settings.address.placeholder': 'Dirección',
  'pages.settings.saveSettings': 'Guardar cambios',

  // region Account Settings page - Billings tab
  'pages.billings.header': 'Facturas',
  'pages.billings.alertMessage': 'Esta es la página de facturación',
  'pages.billings.questionnaire': 'Cuestionario',
  'pages.billings.customer': 'Cliente',
  'pages.billings.journey': 'Journey',
  'pages.billings.event': 'Evento',
  'pages.billings.period': 'Período',
  'pages.billings.created': 'Creado',
  'pages.billings.mode': 'Modo',
  'pages.billings.subtotal': 'Subtotal',
  'pages.billings.discount': 'Descuento',
  'pages.billings.total': 'Total',
  'pages.BillingDetails': 'Detalles de facturación',
  // endregion

  // region Account Settings page - Language tab
  'pages.language.title': 'Elija su idioma:',
  'pages.language.english': 'Inglés',
  'pages.language.german': 'Alemán',
  'pages.language.french': 'Francés',
  // endregion
  // endregion

  // region Profile Page
  'pages.profile.firstName': 'Nombre',
  'pages.profile.lastName': 'Apellidos',
  'pages.profile.email': 'Correo electrónico',
  'pages.profile.phone': 'Teléfono',
  'pages.profile.address': 'Dirección',
  'pages.profile.saveChanges': 'Guardar cambios',
  // endregion

  // region Support Page
  'pages.support.header': 'Servicio de asistencia',
  'pages.settings.telephone': 'Teléfono',
  'pages.settings.email': 'Correo electrónico',
  'pages.support.telephone': 'Teléfono',
  'pages.support.email': 'Correo electrónico',
  'pages.support.title': 'Póngase en contacto con nuestro servicio técnico',
  // endregion

  // region Service Request
  'pages.serviceRequest.customer': 'Cliente',
  'pages.customers.created': 'Creado el',
  'pages.serviceRequest.inquiry': 'Consulta',
  'pages.serviceRequest.what': 'Que',
  'pages.serviceRequest.when': 'Cuando',
  'pages.serviceRequest.where': 'Donde',
  'pages.serviceRequest.qualification': 'Titulación',
  // endregion

  // region Date and Weekdays
  'utils.date.7Days': 'Últimos 7 días',
  'utils.date.30Days': 'Últimos 30 días',
  'utils.date.90Days': 'Últimos 90 días',
  'utils.date.180Days': 'Últimos 180 días',
  // endregion

  // region Images Page

  'pages.images.tagGroups': 'Grupos de etiquetas',
  'pages.images.createTagGroup': 'Crear grupo de etiquetas',
  'pages.images.editTag': 'Editar etiqueta',

  'pages.images.tags': 'Etiqueta',
  'pages.images.removeImageConfirmation':
    '¿Está seguro de eliminar esta imagen? La acción no se puede revertir.',
  'pages.images.chooseGroup': 'Elija el grupo para la etiqueta:',
  'pages.images.newTag': 'Etiqueta nueva',
  'pages.images.createTag': 'Crear etiqueta',
  'pages.images.remove': 'Eliminar',
  'pages.images.removeTagGloballyTitle': '¿Seguro que quiere eliminar esta etiqueta?',
  'pages.images.removeTagGloballySubTitle':
    'A continuación, la etiqueta se eliminará de todas las imágenes.',
  'pages.images.upload': 'Subir',
  'pages.images.uploadDrawerTitle': 'Subir imágenes',
  'pages.images.uploadDraggerHint': 'Subir imagen con mayor resolución',
  'pages.images.uploadDraggerText':
    'Para subir archivos/carpetas, haga clic o arrastre archivos o carpetas a esta área.',
  'pages.images.file': 'Archivo',
  'pages.images.directory': 'Carpeta',
  'pages.images.selectToBulkEdit': 'Selecciona las imágenes que quieras etiquetar de forma masiva',
  'pages.images.selectTagStatistics': 'Estadísticas',
  'pages.images.deleteSelectedImagesConfirm': '¿Deseas eliminar (x) imágenes?',
  'pages.images.contactSupportToSetUpImageGallery':
    'Actualmente, no estás utilizando tu propia galería de imágenes. Si deseas tener más control sobre el contenido visual, puedes solicitar tu propia galería aquí.',
  'pages.images.noCustomImageGalleryAvailable':
    'Aún no tienes una galería de imágenes personalizada.',
  'pages.images.requestAccess': 'Solicitar galería propia',

  // endregion

  // region CTA Page
  'pages.cta.performance': 'Click / Conversion',
  'pages.cta.page': 'Página',
  'pages.cta.conversions.help': 'Usuarios que finalizaron el cuestionario con y sin cita.',

  // endregion

  // region Promotions Page

  'pages.promotions.help_1': '¿Qué son las promociones?',
  'pages.promotions.help_2':
    'Con las promociones puedes añadir automáticamente las promociones actuales a tus correos electrónicos. Nada más crear y activar una promoción, los clientes serán informados de esta promoción durante el periodo especificado.',
  'pages.promotions.tableTitle': 'Promociones',
  'pages.promotions.showVideoTutorial': 'Mostrar tutorial en vídeo',
  'pages.promotions.newPromotion': 'Nueva promoción',
  'pages.promotions.addPromotion': 'Añadir',
  'pages.promotions.editPromotion': 'Editar promoción',
  'pages.promotions.removePromotionConfirm': '¿Está seguro de que desea eliminar esta promoción?',
  'pages.promotions.type': 'Tipo de promoción',
  'pages.promotions.modifiedDate': 'Última modificación',

  'pages.promotions.title': 'Título',
  'pages.promotions.start': 'Inicio',
  'pages.promotions.end': 'Fin',
  'pages.promotions.dates': 'Tiempo de ejecución',
  'pages.promotions.removePromotionsSuccess': 'Promoción anulada',
  'pages.promotions.removePromotionsFailed': 'No se ha podido eliminar la promoción',
  'pages.promotions.form': 'Formulario de promoción',
  'pages.promotions.preview': 'Vista previa',
  'pages.promotions.promotionCondition': 'Condiciones generales',
  'pages.promotions.addPromotionCondition': 'Añadir términos y condiciones',
  'pages.promotion.selectPromotionConditionPlaceholder': 'Seleccione una opción',
  'pages.promotions.invalid_error':'Ingrese una URL válida',

  // endregion

  //tag statistics
  'pages.stats.selectTagGroups': 'Seleccione hasta 3 grupos de etiquetas que le gustaría comparar',
  'pages.stats.tagCombination': 'Combinación de etiquetas',
  'pages.stats.frequency': 'Frecuencia',
  //endregion

  // region Settings - visibility
  'pages.settings.visibility.active': 'Activo',
  'pages.settings.visibility.active.tooltip': 'Puedes desactivar tu Journey en todas las páginas',
  ...transformObjectToLocaleMap('pages.settings.visibility.example', {
    hint: 'Puedes ingresar una URL o una expresión regular, para filtros que cubran sitios basados en reglas.',
    exampleTitle: 'Ejemplos',
    urls: [
      {
        value: '/cocina/i',
        description: 'Todas las URLs de página que contienen "cocina"',
      },
      {
        value: '/cocina/(.*)/i',
        description: 'Todos los sub-sitios de cocina que contienen "cocina/"',
      },
      {
        value: '/https://domain.com/cocina/i',
        description: 'Todas las páginas que comienzan con "https://domain.com/cocina"',
      },
    ],
  }),
  'pages.settings.visibility.blackList': 'Excluir páginas',
  'pages.settings.visibility.blackList.tooltip':
    'El journey solo se mostrará en las páginas listadas aquí',
  'pages.settings.visibility.whiteList': 'Mostrar solo en',
  'pages.settings.visibility.whiteList.tooltip':
    'El Journey se mostrará en todas las páginas excepto las listadas',
  'pages.settings.configuration.editSuccessMessage': '¡Configuración actualizada con éxito!',
// endregion

   // region ForbiddenPage Page
   'pages.ForbiddenPage.message': 'No tiene permiso para acceder a esta página.',
   'pages.users.newUser': 'Nuevo usuario',
   'pages.users.removeUserConfirmation':'¿Está seguro de que desea eliminar el usuario?',
   'pages.users.tableTitle':'Usuarios',
   'pages.users.user':'Usuario',
   'pages.users.email':'Correo electrónico',
   "pages.users.role":"Rol",
   'pages.users.selectPlaceholder': 'Seleccione una función',
  'pages.users.addUser': 'Añadir usuario',
  'pages.users.close': 'Cerrar',
   // endregion
  
};
