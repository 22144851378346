import {transformObjectToLocaleMap} from '@/utils/intl';

export default {
  // region Login Page
  'pages.login.welcome': 'Willkommen! Melden Sie sich an',
  'pages.layouts.userLayout.title': 'Starten Sie Ihr Küchenverkaufsgespräch online',
  'pages.login.username.placeholder': 'E-Mail Adresse',
  'pages.login.password.placeholder': 'Passwort',
  'pages.login.rememberMe': 'Angemeldet bleiben',
  'pages.login.forgotPassword': 'Passwort vergessen?',
  'pages.login.dontHaveAnAccount': 'Noch kein Account?',
  'pages.login.requestDemo': 'Demo termine',
  'pages.login.submit': 'Login',
  'pages.login.password.required': 'Bitte geben Sie Ihr Passwort ein.',
  'pages.login.failure': 'Login fehlgeschlagen, bitte versuchen Sie es erneut.',
  'pages.login.accountLogin.errorMessage': 'Fallscher Nutzername/Passwort',
  'pages.login.username.required': 'Bitte geben Sie Ihren Nutzernamen ein.',
  'pages.login.success': 'Login erfolgreich!',
  'pages.login.loginTitle': 'Willkommen! Loggen Sie sich ein',
  'pages.login.dontHaveAccount': 'Noch keinen Account?',
  'app.footer.imprint': 'Impressum',
  'app.footer.policy': 'Richtlinie',
  'pages.login.email.required': 'Bitte geben Sie Ihre E-Mail Adresse ein',

  // endregion

  // region Reset Password Page
  'pages.resetPassword.setNewPassword': 'Neues Passwort festlegen',
  'pages.resetPassword.placeholder': 'Passwort',
  'pages.resetPassword.placeholderConfirm': 'Passwort bestätigen',
  'pages.resetPassword.passwordRequired': 'Bitte Passwort eingeben',
  'pages.resetPassword.backToLogin': 'Zurück zum Login',
  'pages.resetPassword.title': 'Setzen Sie ein neues Passwort',
  'pages.resetPassword.subTitle': 'Geben Sie ein neues Passwort ein',
  'pages.resetPassword.errorMessage':
    'Ihr Passwort muss aus mindestens 8 Zeichen bestehen, einem Großbuchstaben, einem Kleinbuchstaben, einer Zahl und einem Sonderzeichen.',
  'pages.resetPassword.wrongConfirmation':
    'Die beiden von Ihnen eingegebenen Passwörter stimmen nicht überein',
  'pages.forgotPassword.hint':
    'An welche E-Mail Adresse sollen die Passwort-Zurücksetzen Informationen gehen?',
  'pages.forgotPassword.forgotPasswordTitle': 'Passwort zurücksetzen',
  'pages.forgotpassword.required': 'Erforderliches Feld, bitte geben Sie Ihre E-Mail an.',
  // endregion

  // region Forgot Password Page
  'pages.forgotPassword.label': 'E-Mail Adresse',
  'pages.forgotPassword.required': 'Erforderliches Feld, bitte geben Sie Ihre E-Mail an.',
  'pages.forgotPassword.placeholder': 'E-Mail Adresse',
  'pages.forgotPassword.tooltip':
    'Wenn Sie Ihre E-Mail Adresse vergessen haben, kontaktieren Sie uns bitte über support@conversional.de',
  'pages.forgotPassword.errorMessage':
    'Es ist ein Fehler mit Ihrer E-Mail Adresse aufgetreten, bitte schreiben Sie uns über die Kontaktseite.',
  'pages.forgotPassword.invalid':
    'Ihre eingegebene E-Mail ist nicht registriert, bitte geben Sie eine gültige Adresse ein.',
  'pages.forgotPassword.submit': 'Termin senden',
  'pages.forgotPassword.login': 'Zurück zum Login',
  'pages.forgotPassword.guidText':
    'An welche E-Mail Adresse sollen die Passwort-Zurücksetzen Informationen gehen?',
  'pages.forgotPassword.sentMail': 'E-Mail zum Zurücksetzen des Passworts erfolgreich gesendet.',
  'pages.forgotPassword.sentMailSubtitle': 'Bitte überprüfen Sie auch Ihren Spam-Ordner.',
  // endregion

  // region Dashboard Page
  'pages.dashboard.header': 'Dashboard',
  // endregion

  // region Marketing Page
  'pages.marketing.header': 'Marketing',
  'pages.marketing.noChart': 'Kein Chart vorhanden. Ändern Sie den Datumsfilter',
  // endregion

  // region Performance Page
  'pages.performance.header': 'Performance',
  'pages.performance.alertMessage': 'Dies ist die Performance Seite',
  'pages.performance.cardTitle': 'Performance Funnel',
  'pages.performance.instantAppointmentPath': 'Terminpfad',
  'pages.performance.inspirationPath': 'Inspirationspfad',
  'pages.performance.infoText':
    'Diese Daten basieren auf Tracking-Ereignissen. Durch Tracking-Blocker und Sampling werden dabei nicht alle User erfasst. Es kann zu Abweichungen vom Dashboard kommen.',
  'pages.performance.paths': 'Pfade',
  'pages.performance.explanation':
    'Pfade werden aus dem Fragebogen heraus gestartet. Ihre "gestartet" werte beziehen sich auf den Pfad, nicht auf die Tool-Starts. Daher sind die Starts aller Pfade geringer als die Tool-Starts, wenn Nutzer im ersten Schritt abbrechen.',
  // endregion

  // region Customers Page
  'pages.customers.header': 'Kunden',
  'pages.customers.tableTitle': 'Kunden',
  'pages.customers.engagementScore': 'Engagement-Bewertung',
  'pages.customers.scoreExplanation':
    'Wahrscheinlichkeit, dass der Kunde bei Ihnen kauft basierend auf allen bisherigen Interaktionen.',
  'pages.customers.name': 'Name',
  'pages.customers.gender': 'Geschlecht',
  'pages.customers.email': 'E-Mail',
  'pages.customers.utmMedium': 'UTM Medium',
  'pages.customers.status': 'Status',
  'pages.customers.avtiveRecipient': 'Aktiver Empfänger',
  'pages.customers.doubleOptin': 'Double opt-in',
  'pages.customers.createDate': 'Erstellungsdatum',
  'pages.customers.actions': 'Aktionen',
  'pages.customers.details': 'Details',
  'pages.customers.actions.download': 'Als CSV herunterladen',
  'pages.customers.profile': 'Profil',
  'pages.customers.firstName': 'Vorname',
  'pages.customers.lastName': 'Nachname',
  'pages.customers.styles': 'Vorlieben',
  'pages.customers.qualification': 'Qualifizierung',
  'pages.customers.phone': 'Telefon',
  'pages.customers.activeRecipient': 'Aktiver Empfänger',
  'pages.customer.unsubscribe': 'Abmelden',
  'pages.customer.qualification': 'Qualifizierung',
  'pages.customer.state': 'Status',
  'pages.customer.orderVolume': 'Order Volumen',
  'pages.customer.notes': 'Notizen',
  'pages.customer.results': 'Ergebnisse',
  'pages.customer.uploadedFiles': 'Hochgeladene Dateien',
  'pages.customer.noUploadedFiles': 'Keine Hochgeladenen Dateien',
  // endregion

  // region Requests Page
  'pages.requests.header': 'Kunden',
  'pages.requests.tableTitle': 'Termine',
  'pages.requests.customer': 'Kunde',
  'pages.requests.appointmentRequest': 'Terminanfrage',
  'pages.requests.what': 'Was',
  'pages.requests.dateOfRequest': 'Termindatum',
  'pages.requests.where': 'Wo',
  'pages.requests.actions': 'Aktionen',
  // endregion

  // region Locations page
  'pages.locations.header': 'Standorte',
  'pages.locations.alertMessage': 'Dies ist die Standortseite',
  'pages.locations.location': 'Standort',
  'pages.locations.address': 'Adresse',
  'pages.locations.videoCall': 'Videoanruf',
  'pages.locations.created': 'Erstellungsdatum',
  'pages.locations.actions': 'Aktionen',
  'pages.locations.details': 'Details',
  'pages.location.edit': 'Bearbeiten',
  'pages.locations.availability': 'Öffnungszeiten',
  'pages.locations.blockHours': 'Nichtverfügbarkeit',
  'pages.locations.additionalOpenHours': 'Zusätzliche Öffnungszeiten',
  'pages.locations.from': 'Von',
  'pages.locations.to': 'bis',
  'pages.locations.toHour': 'bis',
  'pages.locations.fromHour': 'Von',
  'pages.location.removeRecurringTime': 'Möchten Sie die wiederkehrenden Zeiten entfernen?',
  'pages.locations.download': 'Als CSV herunterladen',
  'pages.common.add': 'Hinzufügen',
  'pages.common.remove': 'Verwerfen',
  'pages.common.edit': 'Bearbeiten',
  'pages.common.actions': 'Actions',
  'pages.common.clear': 'Abwählen',
  'pages.common.clearAll': 'Alle entfernen',
  'pages.common.save': 'Speichern',
  'pages.common.discard': 'Verwerfen',
  'pages.common.selected': 'Ausgewählt',
  'pages.common.yes': 'ja',
  'pages.common.no': 'nein',
  'pages.common.ok': 'OK',
  'pages.common.cancel': 'Abbrechen',
  'pages.common.tutorial': 'Tutorial',
  'pages.common.confirm': 'Weiter',
  'pages.images.deleteSelectedImagesSuccess': 'Bilder wurden gelöscht',
  'pages.location.removeBlockPeriod': 'Möchten Sie die nichtverfügbaren Zeiten entfernen?',
  'pages.location.removeOpenPeriod': 'Sollen diese Öffnungszeiten wirklich entfernt werden?',
  'pages.location.name': 'Name',
  'pages.location.home': 'Zu Hause',
  'pages.location.store': 'Filiale',
  'pages.location.online': 'Online',
  'pages.location.type': 'Art',
  'pages.location.type_tooltip':
    'Der Typ eines Standorts beschreibt, welche Art von Termin gerade gebucht wird.',
  'pages.location.address': 'Addresse',
  'pages.location.createDate': 'Erstellungsdatum',
  'pages.location.videoCallEnabled': 'Aktiv',
  'pages.location.videoCallDisabled': 'Inaktiv',
  'pages.location.videoCall': 'Videoanruf',
  'pages.location.details': 'Details',
  'pages.locations.currentRecurring': 'Aktuelle wiederkehrende Zeiten',
  'pages.locations.resources': 'Ressourcen',
  'pages.locations.meta': 'Benutzerdefinierte Felder',
  'pages.locations.resourceCountLimitation':
    'Jeder Standort kann über maximal 5 Ressourcen verfügen. Bitte wenden Sie sich an den Support, falls Sie mehr Ressourcen benötigen.',
  'pages.locations.atLeastOneResource':
    'Jeder Standort muss über wenigstens eine Ressource verfügen.',
  'pages.location.removeResource': 'Sind Sie sicher, dass Sie die Ressource entfernen wollen?',
  'pages.locations.addNewResource': 'Neue Ressource',
  'pages.locations.removeResourceSuccess': 'Ressource wurde entfernt.',
  'pages.locations.removeResourceFailed': 'Ressource konnte nicht entfernt werden.',
  'pages.locations.addNewResourceSuccess': 'Neue Ressource hinzugefügt.',
  'pages.locations.addNewResourceFailed': 'Ressource konnte nicht hinzugefügt werden.',
  'pages.location.resource.name': 'Name',
  'pages.location.resource.email': 'Email',
  // Location Removal
  'pages.locations.removeLocationConfirm': 'Den Standort jetzt entfernen?',
  'pages.locations.removeLocationSuccess': 'Der Standort wurde entfernt.',
  'pages.locations.removeLocationFailed': 'Standort entfernen fehlgeschlagen',
  //
  'pages.resource.name': 'Name',
  'pages.resource.surname': 'Nachname',
  'pages.resource.email': 'Email',

  // endregion

  // region Account Settings
  'pages.settings.header': 'Account Einstellungen',
  'pages.settings.profileTab': 'Profil',
  'pages.settings.billingTab': 'Abrechnung',
  'pages.settings.languageTab': 'Sprache',
  'pages.settings.firstName.label': 'Vorname',
  'pages.settings.firstName.required': 'Erforderliches Feld, bitte geben Sie Ihren Vornamen an',
  'pages.settings.firstName.placeholder': 'Vorname',
  'pages.settings.firstName.errorMessage':
    'Ihr Vorname kann keine Zahlen und Symbole enthalten, bitte korrigieren Sie Ihre Eingabe',
  'pages.settings.lastName.label': 'Nachname',
  'pages.settings.lastName.required': 'Erforderliches Feld, bitte geben Sie Ihren Nachnamen an',
  'pages.settings.lastName.placeholder': 'Nachname',
  'pages.settings.lastName.errorMessage':
    'Ihr Vorname kann keine Zahlen und Symbole enthalten, bitte korrigieren Sie Ihre Eingabe',
  'pages.settings.email.label': 'E-Mail Adresse',
  'pages.settings.email.required': 'Erforderliches Feld, bitte geben Sie Ihre E-Mail Adresse an',
  'pages.settings.email.placeholder': 'E-Mail Adresse',
  'pages.settings.email.errorMessage':
    'Es ist ein Fehler mit Ihrer E-Mail Adresse aufgetreten, bitte verwenden Sie ein gültiges Format',
  'pages.settings.phone.label': 'Telefon',
  'pages.settings.phone.required': 'Erforderliches Feld, bitte geben Sie Ihre Telefonnummer an',
  'pages.settings.phone.placeholder': 'Telefon',
  'pages.settings.phone.errorMessage':
    'Ihre Telefonnummer kann keine Buchstaben enthalten, bitte korrigieren Sie Ihre Eingabe',
  'pages.settings.address.label': 'Adresse',
  'pages.settings.address.required': 'Erforderliches Feld, bitte geben Sie Ihre Adresse an',
  'pages.settings.address.placeholder': 'Adresse',
  'pages.settings.saveSettings': 'Änderungen speichern',
  // region Account Settings page - Billings tab
  'pages.billings.header': 'Abrechnung',
  'pages.billings.alertMessage': 'Dies ist die Abrechnungsseite',
  'pages.billings.email': 'E-Mail',
  'pages.billings.questionnaire': 'Fragebogen',
  'pages.billings.customer': 'Kunde',
  'pages.billings.journey': 'Journey',
  'pages.billings.event': 'Ereignis',
  'pages.billings.created': 'Erstellt',
  'pages.billings.mode': 'Modus',
  'pages.billings.subtotal': 'Zwischensumme',
  'pages.billings.discount': 'Rabatt',
  'pages.billings.total': 'Gesamt',
  'pages.BillingDetails': 'Abrechnung',
  // endregion
  // region Account Settings page - Language tab
  'pages.language.title': 'Sprache wählen',
  'pages.language.english': 'Englisch',
  'pages.language.german': 'Deutsch',
  'pages.language.french': 'Französisch',
  // endregion
  // endregion

  // region Profile Page
  'pages.profile.firstName': 'Vorname',
  'pages.profile.lastName': 'Nachname',
  'pages.profile.email': 'E-Mail',
  'pages.profile.phone': 'Telefon',
  'pages.profile.address': 'Addresse',
  'pages.profile.saveChanges': 'Änderungen speichern',
  // endregion

  // region Support Page
  'pages.support.header': 'Support',
  'pages.settings.telephone': 'Telefon',
  'pages.settings.email': 'E-Mail',
  'pages.support.telephone': 'Telefon',
  'pages.support.email': 'E-Mail',
  'pages.support.title': 'Kontaktieren Sie unseren technischen Support',
  // endregion

  // region Service Request
  'pages.serviceRequest.customer': 'Kunde',
  'pages.customers.created': 'Erstellt am',
  'pages.serviceRequest.inquiry': 'Termin',
  'pages.serviceRequest.what': 'Was',
  'pages.serviceRequest.when': 'Wann',
  'pages.serviceRequest.where': 'Wo',
  'pages.serviceRequest.qualification': 'Qualifizierung',
  // endregion

  // region Date and Weekdays
  'utils.date.7Days': 'Letzte 7 Tage',
  'utils.date.30Days': 'Letzte 30 Tage',
  'utils.date.90Days': 'Letzte 90 Tage',
  'utils.date.180Days': 'Letzte 180 Tage',
  // endregion

  // region Images Page

  'pages.images.tagGroups': 'Tag-Gruppe',
  'pages.images.createTagGroup': 'Tag-Gruppe erstellen',

  'pages.images.tags': 'Tags',
  'pages.images.removeImageConfirmation':
    'Soll das Bild wirklich gelöscht werden? Diese Aktion kann nicht rückgängig gemacht werden.',
  'pages.images.chooseGroup': 'Wähle eine Gruppe für das Tag:',
  'pages.images.newTag': 'Neues Tag',
  'pages.images.createTag': 'Tag erstellen',
  'pages.images.editTag': 'Tag bearbeiten',
  'pages.images.remove': 'Entfernen',
  'pages.images.removeTagGloballyTitle': 'Soll das Tag wirklich entfernt werden?',
  'pages.images.removeTagGloballySubTitle': 'Das Tag wird dann von allen Bildern entfernt.',
  'pages.images.upload': 'Hochladen',
  'pages.images.uploadDrawerTitle': 'Bild hochladen',
  'pages.images.uploadDraggerHint': 'Bild mit der höchsten Auflösung hochladen',
  'pages.images.uploadDraggerText':
    'Zum Hochladen, Dateien oder Ordner auf diesen Bereich ziehen oder klicken.',
  'pages.images.file': 'Datei',
  'pages.images.directory': 'Ordner',
  'pages.images.selectToBulkEdit': 'Wählen Sie Bilder aus, die Sie in großen Mengen taggen möchten',
  'pages.images.selectTagStatistics': 'Statistiken',
  'pages.images.deleteSelectedImagesConfirm': 'Wollen Sie (x) Bilder löschen?',
  'pages.images.setupCustomImageGallery':
    'Wir können Ihre ganz eigene Bildergalerie einrichten. Bitte kontaktieren Sie unseren Support für die nächsten Schritte.',
  'pages.images.noCustomImageGalleryAvailable':
    'Sie haben noch keine benutzerdefinierte Bildergalerie.',
  'pages.images.contactSupportToSetUpImageGallery':
    'Aktuell verwenden Sie keine eigene Bildergalerie. Wenn Sie mehr Kontrolle über das Bildmaterial möchten, können Sie hier eine eigene Galerie anfragen.',
  'pages.images.requestAccess': 'Eigene Galerie anfragen',

  // endregion

  // region CTA Page
  'pages.cta.performance': 'Click / Conversion',
  'pages.cta.page': 'Seite',
  'pages.cta.conversions.help': 'Benutzer, die den Fragebogen mit und ohne Termin ausgefüllt haben',
  // endregion

  // region Promotions Page

  'pages.promotions.help_1': 'Was sind Promotions?',
  'pages.promotions.help_2':
    'Promotions fügen automatisch aktuelle Werbeaktionen zu Ihren E-Mails hinzu. Sobald Sie eine Promotion anlegen und aktivieren, werden Kunden im angegebenen Zeitraum auf diese Aktion aufmerksam gemacht.',
  'pages.promotions.tableTitle': 'Promotions',
  'pages.promotions.showVideoTutorial': 'Video Tutorial anzeigen',
  'pages.promotions.newPromotion': 'Neue Promotion',
  'pages.promotions.addPromotion': 'Hinzufügen',
  'pages.promotions.editPromotion': 'Promotion bearbeiten',
  'pages.promotions.removePromotionConfirm':
    'Sind Sie Sicher, dass Sie diese Promotion löschen wollen?',
  'pages.promotions.type': 'Promotion Typ',
  'pages.promotions.modifiedDate': 'Zuletzt geändert',
  'pages.promotions.title': 'Titel',
  'pages.promotions.start': 'Start',
  'pages.promotions.end': 'Ende',
  'pages.promotions.dates': 'Laufzeit',
  'pages.promotions.removePromotionsSuccess': 'Promotion gelöscht',
  'pages.promotions.removePromotionsFailed': 'Promotion konnte nicht gelöscht werden',
  'pages.promotions.form': 'Formular',
  'pages.promotions.preview': 'Vorschau',
  'pages.promotions.promotionCondition': 'Bedingungen und Konditionen',
  'pages.promotions.addPromotionCondition': 'Bedingungen und Konditionen hinzufügen',
  'pages.promotion.selectPromotionConditionPlaceholder': 'Bitte auswählen',
  'pages.promotions.invalid_error':'Bitte geben Sie eine gültige URL ein',

  // endregion

  //tag statistics
  'pages.stats.selectTagGroups': 'Wählen Sie bis zu 3 Tag-Gruppen aus, die Sie vergleichen möchten',
  'pages.stats.tagCombination': 'Tag Kombination',
  'pages.stats.frequency': 'Häufigkeit',
  //endregion

  // region Settings - visibility
  'pages.settings.visibility.active': 'Aktiv',
  'pages.settings.visibility.active.tooltip':
    'Sie können diese Journey auf allen Seiten deaktivieren',
  'pages.settings.visibility.clearRules':
    'Bitte entfernen Sie zuerst alle Regeln, um den Modus zu ändern.',
  'pages.settings.visibility.urlExample': `Sie können eine URL oder einen regulären Ausdruck verwenden, um regelbasiert die URL zu filtern.

Beispiele

kuechen         Alle Seiten mit URLs, die "kuechen" enthalten
kuechen/(.*)        Alle Unterseiten von "kuechen"
^https://domain.de/kuechen          Alle Seiten, die mit "^https://domain.de/kuechen" beginnen`,
  ...transformObjectToLocaleMap('pages.settings.visibility.example', {
    hint: 'Sie können eine URL oder einen regulären Ausdruck verwenden, um regelbasiert die URL zu filtern.',
    exampleTitle: 'Beispiele',
    urls: [
      {
        value: '/kuechen/i',
        description: 'Alle Seiten mit URLs, die "kuechen" enthalten',
      },
      {
        value: '/kuechen/(.*)/i',
        description: 'Alle Unterseiten von "kuechen"',
      },
      {
        value: '/https://domain.de/kuechen/i',
        description: 'Alle Seiten, die mit "https://domain.de/kuechen" beginnen',
      },
    ],
  }),
  'pages.settings.visibility.blackList': 'Seiten ausschließen',
  'pages.settings.visibility.blackList.tooltip':
    'Die Journey wird auf allen Seiten außer den hier aufgeführten angezeigt',
  'pages.settings.visibility.whiteList': 'Nur sichtbar auf',
  'pages.settings.visibility.whiteList.tooltip':
    'Die Journey wird nur auf den hier aufgeführten Seiten gezeigt',
  'pages.settings.configuration.editSuccessMessage': 'Einstellungen erfolgreich aktualisiert!',
  // endregion

     // region ForbiddenPage Page
     'pages.ForbiddenPage.message': 'Sie sind nicht berechtigt, auf diese Seite zuzugreifen',
   'pages.users.newUser': 'Nutzer hinzufügen',
   'pages.users.removeUserConfirmation':'Sind Sie sicher, dass Sie den Nutzer löschen möchten?',
   'pages.users.tableTitle':'Benutzer',
   'pages.users.user':'Benutzer',
   'pages.users.email':'E-Mail',
   "pages.users.role":"Rolle",
   'pages.users.selectPlaceholder': 'Bitten wählen Sie eine Rolle',
  'pages.users.addUser': 'Neuen Nutzer anlegen',
  'pages.users.close': 'Schließen',


     // endregion
};
